"use client";

import Link from "next/link";
import { signOut } from "next-auth/react";
import { FaSignOutAlt } from "react-icons/fa";

interface HeaderProps {
  name: string;
  desc: string;
  link?: string;
}

export default function Header({ name, desc, link }: HeaderProps) {
  return (
    <header className="relative bg-gradient-to-br from-gray-900/95 to-black/95 backdrop-blur-sm">
      <div className="container mx-auto px-6 py-6 flex flex-col sm:flex-row justify-between items-center">
        <div className="flex items-center space-x-5 mb-4 sm:mb-0">
          {link ? (
            <Link
              href={link}
              className="rounded-lg hover:scale-105 transition-transform"
            >
              <img
                src={process.env.basePath + "/BME.svg"}
                alt="BME logo"
                className="h-12 w-auto white-logo drop-shadow-lg"
              />
            </Link>
          ) : (
            <Link
              href="/"
              className="rounded-lg hover:scale-105 transition-transform"
            >
              <img
                src={process.env.basePath + "/BME.svg"}
                alt="BME logo"
                className="h-12 w-auto white-logo drop-shadow-lg"
              />
            </Link>
          )}
          <div className="text-center sm:text-left bg-white/5 rounded-xl px-4 py-2 backdrop-blur-sm">
            <h1 className="text-xl font-semibold text-white sm:text-3xl">
              {name}
            </h1>
            <p className="text-xs font-light text-white/90 sm:text-base hidden sm:block">
              {desc}
            </p>
          </div>
        </div>
        <nav className="flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0">
          <div className="flex sm:space-x-2">
            <Link
              href="/contact"
              className="text-white hover:text-bme-main-hover transition-all duration-200 px-4 py-2.5 rounded-lg backdrop-blur-sm hidden sm:block"
            >
              Kapcsolat
            </Link>
          </div>
          <button
            onClick={() =>
              signOut({ callbackUrl: process.env.basePath + "/login" })
            }
            className="px-5 py-2.5 text-sm text-white rounded-lg hover:scale-105 transition-all bg-bme-main/90 hover:bg-bme-main-hover backdrop-blur-sm hover:text-white shadow-lg sm:ml-6"
          >
            <FaSignOutAlt className="sm:hidden" />
            <span className="hidden sm:block">Kijelentkezés</span>
          </button>
        </nav>
      </div>
      <div className="absolute bottom-0 left-0 right-0 h-1 bg-gradient-to-r from-transparent via-bme-main to-transparent opacity-80"></div>
    </header>
  );
}
